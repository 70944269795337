import { React, useState } from 'react';
import propTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import useFetchNightStaffings from 'hooks/api/businessType/useFetchNightStaffings';
import useFetchUnitTypes from 'hooks/api/businessType/useFetchUnitTypes';

import UIFoldableCard from 'components/KubenAdmin/UIAdmin/UIFoldableCard';
import UIInputWrapper from 'components/KubenAdmin/UIAdmin/UIInputWrapper';
import UIAdvancedTable from 'components/KubenAdmin/UITable/UIAdvancedTable';
import UIAdvancedTableElement from 'components/KubenAdmin/UITable/UIAdvancedTableElement';
import TemplateInput from 'components/KubenAdmin/TemplatesPage/TemplateInput';

import UIButtonDropdownGroup from 'components/global/UIButtons/UIButtonDropdownGroup';
import UIItemDropdown from 'components/global/UIButtons/UIItemDropdown';
import UIButtonSubmit from 'components/global/UIButtons/UIButtonSubmit';
import UIInput from 'components/global/UICommon/UIInput';
import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';

import UIAddElementModal from 'components/KubenAdmin/UIAdmin/UIAddElementModal';
import UIListModal from 'components/global/UIModals/UIListModal';

const BusinessValuesVersionsForm = ({
  setElement,
  referenceVersions,
  element,
  unitTypes,
  nightStaffings,
  levels,
  isReadonly,
  onOpenAddNewVersionModal,
  onChangeVersion,
}) => {
  const { t } = useTranslation();

  const [isAddLevel, setAddLevel] = useState(false);
  const [isAddUnitType, setAddUnitType] = useState(false);
  const [isAddNightStaffing, setAddNightStaffing] = useState(false);

  const {
    data: allNightStaffings,
    isLoading: isNightStaffingsLoading,
    isError: isNightStaffingsError,
  } = useFetchNightStaffings();
  const { data: allUnitTypes, isLoading: isUnitTypesLoading, isError: isUnitTypesError } = useFetchUnitTypes();

  const onAddLevel = (text) => {
    setElement((prev) => {
      const highestLevel = prev.levels.length > 0 ? Math.max(...prev.levels.map((level) => level.level)) : -1;
      const newLevel = {
        level: highestLevel + 1,
        name: text,
        minPoints: 0,
        maxPoints: 0,
      };
      const updatedStaffings =
        prev.staffings.length > 0
          ? prev.staffings.map((staffing) => {
              const newStaffingValues = prev.unitTypeIds.map((unitType) => ({
                level: newLevel.level,
                unitTypeId: unitType,
                fullTimeEmployees: 0,
              }));

              return {
                ...staffing,
                staffingByUnitTypeAndLevel: staffing.staffingByUnitTypeAndLevel
                  ? [...staffing.staffingByUnitTypeAndLevel, ...newStaffingValues]
                  : newStaffingValues,
              };
            })
          : [
              {
                id: uuidv4(),
                key: 'Default',
                staffingByUnitTypeAndLevel: prev.unitTypeIds.map((unitType) => ({
                  level: newLevel.level,
                  unitTypeId: unitType,
                  fullTimeEmployees: 0,
                })),
              },
            ];

      return {
        ...prev,
        levels: [...prev.levels, newLevel],
        staffings: updatedStaffings,
      };
    });
  };

  const onAddUnitType = (newUnitType) => {
    setElement((prev) => {
      const updatedStaffings =
        prev.staffings.length > 0
          ? prev.staffings.map((staffing) => {
              const newStaffingValues = prev.levels.map((level) => ({
                level: level.level,
                unitTypeId: newUnitType,
                fullTimeEmployees: 0,
              }));

              return {
                ...staffing,
                staffingByUnitTypeAndLevel: staffing.staffingByUnitTypeAndLevel
                  ? [...staffing.staffingByUnitTypeAndLevel, ...newStaffingValues]
                  : newStaffingValues,
              };
            })
          : [
              {
                id: uuidv4(),
                key: 'Default',
                staffingByUnitTypeAndLevel: prev.levels.map((level) => ({
                  level: level.level,
                  unitTypeId: newUnitType,
                  fullTimeEmployees: 0,
                })),
              },
            ];

      const updatedStaffingByUnitTypeAndNightStaffings = prev.nightStaffingIds.map((nightStaffing) => ({
        nightStaffingId: nightStaffing,
        unitTypeId: newUnitType,
        fullTimeEmployees: 0,
      }));

      return {
        ...prev,
        unitTypeIds: [...prev.unitTypeIds, newUnitType],
        staffings: updatedStaffings,
        staffingByUnitTypeAndNightStaffings: [
          ...prev.staffingByUnitTypeAndNightStaffings,
          ...updatedStaffingByUnitTypeAndNightStaffings,
        ],
      };
    });
  };

  const onAddNightStaffing = (newNightStaffing) => {
    setElement((prev) => {
      const newNightStaffingValues = prev.unitTypeIds.map((unitType) => ({
        nightStaffingId: newNightStaffing,
        unitTypeId: unitType,
        fullTimeEmployees: 0,
      }));

      return {
        ...prev,
        nightStaffingIds: [...prev.nightStaffingIds, newNightStaffing],
        staffingByUnitTypeAndNightStaffings: [...prev.staffingByUnitTypeAndNightStaffings, ...newNightStaffingValues],
      };
    });
  };

  const onDelete = (element, type) => {
    setElement((prev) => {
      const { unitTypeIds, nightStaffingIds, levels, staffings, staffingByUnitTypeAndNightStaffings } = prev;

      if (type === 'unitType') {
        const updatedUnitTypes = unitTypeIds.filter((el) => el !== element);

        const updatedStaffings = staffings.map((staffing) => ({
          ...staffing,
          staffingByUnitTypeAndLevel: staffing.staffingByUnitTypeAndLevel.filter(
            (staff) => staff.unitTypeId !== element,
          ),
        }));

        const updatedStaffingByUnitTypeAndNightStaffings = staffingByUnitTypeAndNightStaffings.filter(
          (staff) => staff.unitTypeId !== element,
        );

        return {
          ...prev,
          unitTypeIds: updatedUnitTypes,
          staffings: updatedStaffings,
          staffingByUnitTypeAndNightStaffings: updatedStaffingByUnitTypeAndNightStaffings,
        };
      } else if (type === 'nightStaffing') {
        const updatedNightStaffings = nightStaffingIds.filter((el) => el !== element);

        const updatedStaffingByUnitTypeAndNightStaffings = staffingByUnitTypeAndNightStaffings.filter(
          (staff) => staff.nightStaffingId !== element,
        );

        return {
          ...prev,
          nightStaffingIds: updatedNightStaffings,
          staffingByUnitTypeAndNightStaffings: updatedStaffingByUnitTypeAndNightStaffings,
        };
      } else if (type === 'level') {
        const updatedLevels = levels.filter((el) => el.level !== element.level);

        const updatedStaffings = staffings.map((staffing) => ({
          ...staffing,
          staffingByUnitTypeAndLevel: staffing.staffingByUnitTypeAndLevel.filter(
            (staff) => staff.levelId !== element.level,
          ),
        }));

        return {
          ...prev,
          levels: updatedLevels,
          staffings: updatedStaffings,
        };
      }
    });
  };

  if (isNightStaffingsLoading || isUnitTypesLoading) {
    return <UILoadingModal />;
  }

  if (isNightStaffingsError || isUnitTypesError) {
    return (
      <UIErrorModal
        message={t('UIModals.errorModalMessage')}
        showIcon={false}
        isOpen={true}
      />
    );
  }

  return (
    <UIFoldableCard
      title="Versioner"
      defaultOpen={true}
    >
      <div className="flex flex-col justify-center items-end space-y-4">
        <div className="flex justify-end space-x-6">
          <UIButtonSubmit
            name="Skapa ny version"
            variant="bg-app-assessment"
            onSubmit={onOpenAddNewVersionModal}
            assessments={true}
            isIcon={false}
            fixedWidth={false}
          />
        </div>
        {referenceVersions.length > 1 && (
          <div className="flex items-center text-sm">
            <p className="font-semibold mr-3">Historik</p>
            <UIButtonDropdownGroup
              value={
                (element.startDate != null ? element.startDate : 'Början') +
                (element.endDate != null ? ` - ${element.endDate}` : ' (Nuvarande period)')
              }
              width="w-80"
              absolute="absolute"
              zIndex={'z-30'}
              translate={false}
              style="white"
            >
              {referenceVersions.map((version, index) => (
                <UIItemDropdown
                  value={version.startDate}
                  displayValue={
                    (version.startDate != null ? version.startDate : 'Början') +
                    (version.endDate != null ? ` - ${version.endDate}` : ' (Nuvarande period)')
                  }
                  onChange={() => onChangeVersion(version.startDate)}
                  key={index}
                />
              ))}
            </UIButtonDropdownGroup>
          </div>
        )}
      </div>
      <div className="font-semibold md:text-lg text-xl uppercase pt-4 pb-3">Enhetstyper</div>
      {unitTypes.length == 0 && (
        <div className="md:text-lg text-md">{t('BusinessValuesPage.missingUnitTypesText')}</div>
      )}
      {unitTypes.map((unitType) => (
        <UIInputWrapper
          key={unitType}
          title=""
        >
          <UIInput
            id={`unitType-${unitType}`}
            name={`unitType-${unitType}`}
            value={allUnitTypes.find((el) => el.id === unitType)?.name || ''}
            disabled={true}
            type={'text'}
            onDelete={() => onDelete(unitType, 'unitType')}
          />
        </UIInputWrapper>
      ))}
      <div className="py-4 flex justify-center">
        <UIButtonSubmit
          name="Ny enhetstyp"
          onSubmit={() => setAddUnitType(true)}
          assessments={true}
          isIcon={false}
          fixedWidth={false}
        />
      </div>
      <div className="font-semibold md:text-lg text-xl uppercase pt-4 pb-3">Nattbemanning</div>
      {nightStaffings.length == 0 && (
        <div className="md:text-lg text-md">{t('BusinessValuesPage.missingNightStaffingsText')}</div>
      )}
      {nightStaffings.map((nightStaffing) => (
        <UIInputWrapper
          key={nightStaffing}
          title=""
        >
          <UIInput
            id={`nightStaffing-${nightStaffing}`}
            name={`nightStaffing-${nightStaffing}`}
            value={allNightStaffings.find((el) => el.id === nightStaffing)?.name || ''}
            disabled={true}
            type="text"
            onDelete={() => onDelete(nightStaffing, 'nightStaffing')}
          />
        </UIInputWrapper>
      ))}
      <div className="py-4 flex justify-center">
        <UIButtonSubmit
          name="Ny nattbemanning"
          onSubmit={() => setAddNightStaffing(true)}
          assessments={true}
          isIcon={false}
          fixedWidth={false}
        />
      </div>
      <div className="font-semibold md:text-lg text-xl uppercase pt-4 pb-3">Nivåer</div>
      {levels.length == 0 && <div className="md:text-lg text-md">{t('BusinessValuesPage.missingLevelsText')}</div>}
      <UIAdvancedTable>
        {levels.map((el, idx) => (
          <UIAdvancedTableElement
            key={`key_${el.level}`}
            id={`id_${el.level}`}
            index={idx}
            title={el.name}
            canMove={false}
            onDelete={() => onDelete(el, 'level')}
          >
            <TemplateInput
              title={'namn'}
              value={el.name}
              disabled={isReadonly}
              onChange={(e) => {
                const updatedLevels = levels.map((level) =>
                  level.level === el.level ? { ...level, name: e.target.value } : level,
                );
                setElement((prev) => ({ ...prev, levels: updatedLevels }));
              }}
            />
            <TemplateInput
              title={'min poäng'}
              value={el.minPoints !== 0 ? el.minPoints : '0'}
              disabled={isReadonly}
              type="number"
              onChange={(e) => {
                const updatedLevels = levels.map((level) =>
                  level.level === el.level ? { ...level, minPoints: Number(e.target.value) } : level,
                );
                setElement((prev) => ({ ...prev, levels: updatedLevels }));
              }}
            />
            <TemplateInput
              title={'max poäng'}
              value={el.maxPoints !== 0 ? el.maxPoints : '0'}
              disabled={isReadonly}
              type="number"
              onChange={(e) => {
                const updatedLevels = levels.map((level) =>
                  level.level === el.level ? { ...level, maxPoints: Number(e.target.value) } : level,
                );
                setElement((prev) => ({ ...prev, levels: updatedLevels }));
              }}
            />
          </UIAdvancedTableElement>
        ))}
      </UIAdvancedTable>
      <div className="pb-4 flex justify-center">
        <UIButtonSubmit
          name="Ny nivå"
          onSubmit={() => setAddLevel(true)}
          assessments={true}
          isIcon={false}
          fixedWidth={false}
        />
      </div>
      {isAddLevel && (
        <UIAddElementModal
          modalTitle="Lägg till nivå"
          inputPlaceholder="Namn"
          onSave={(text) => onAddLevel(text)}
          onClose={() => setAddLevel(false)}
          isOpen={true}
        />
      )}
      {isAddUnitType && (
        <UIListModal
          modalTitle="Enhetstyp"
          isOpen={true}
          data={allUnitTypes.filter((unitType) => !unitTypes.includes(unitType.id))}
          onClose={() => setAddUnitType(false)}
          handleClick={(element) => onAddUnitType(element.id)}
          isSearchBar={false}
        />
      )}
      {isAddNightStaffing && (
        <UIListModal
          modalTitle="Nattbemanning"
          isOpen={true}
          data={allNightStaffings.filter((nightStaffing) => !nightStaffings.includes(nightStaffing.id))}
          onClose={() => setAddNightStaffing(false)}
          handleClick={(element) => onAddNightStaffing(element.id)}
          isSearchBar={false}
        />
      )}
    </UIFoldableCard>
  );
};

BusinessValuesVersionsForm.propTypes = {
  setElement: propTypes.func,
  element: propTypes.any,
  unitTypes: propTypes.array,
  nightStaffings: propTypes.array,
  levels: propTypes.array,
  isReadonly: propTypes.bool,
  onOpenAddNewVersionModal: propTypes.func,
  isAdd: propTypes.bool,
  referenceVersions: propTypes.array,
  onChangeVersion: propTypes.func,
};

export default BusinessValuesVersionsForm;
