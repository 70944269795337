import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FiHome } from 'react-icons/fi';
import { MdOutlineSpaceDashboard, MdOutlinePlace, MdOutlineCalendarToday, MdOutlinePortrait } from 'react-icons/md';
import { useRecoilState, useRecoilValue, useResetRecoilState } from 'recoil';

import { downloadXLSFile } from 'api/downloadReport';
import formatDate from 'utils/KubenAssessments/formatDate';
import filterBySearch from 'utils/global/filterBySearch';

import { searchQuery } from 'state/global/searchQuery';
import { currentUser } from 'state/global/currentUser';
import { templatesFiltered as templatesFilteredRecoil } from 'state/global/templates';
import {
  reportsDateRange,
  chosenReportDateRange as chosenReportDateRangeRecoil,
} from 'state/KubenReports/reportsDateRange';
import {
  reportTypes as reportTypesRecoil,
  reportTypesFiltered as reportTypesFilteredRecoil,
  chosenReportType as chosenReportTypeRecoil,
} from 'state/KubenReports/reportTypes';
import {
  reportTemplates as reportTemplatesRecoil,
  chosenReportTemplate as chosenReportTemplateRecoil,
  reportTemplatesFiltered as reportTemplatesFilteredRecoil,
} from 'state/KubenReports/reportTemplates';
import {
  reportRegionsFiltered as reportRegionsFilteredRecoil,
  reportRegions as reportRegionsRecoil,
  chosenReportRegion as chosenReportRegionRecoil,
} from 'state/KubenReports/reportRegions';
import { reportData as reportDataRecoil } from 'state/KubenReports/reportData';
import { jobStatus } from 'state/global/jobStatus';

import useFetchReportTypes from 'hooks/api/reportDefinition/useFetchReportTypes';
import useFetchTemplates from 'hooks/api/assessment/useFetchTemplates';
import useFetchReportRegions from 'hooks/api/assessment/useFetchReportRegions';
import useFetchReportUnitsForRegion from 'hooks/api/report/useFetchReportUnitsForRegion';
import useCreateReport from 'hooks/api/report/useCreateReport';
import useFetchJobStatus from 'hooks/api/job/useFetchJobStatus';

import UILoadingModal from 'components/global/UIModals/UILoadingModal';
import UIButtonSubmit from 'components/global/UIButtons/UIButtonSubmit';
import UIErrorModal from 'components/global/UIModals/UIErrorModal';
import UIStaticComponentsWrapper from 'components/global/UICommon/UIStaticComponentsWrapper';
import UIButtonCheckbox from 'components/global/UIButtons/UIButtonCheckbox';

import ReportsPageReportItem from 'components/KubenReports/ReportsPageReportItem';

const ReportsPage = () => {
  const { t } = useTranslation();
  const user = useRecoilValue(currentUser);
  const search = useRecoilValue(searchQuery);

  // chosing report type mechanism
  const { isLoading: isReportTypesLoading, isError: isReportTypesError } = useFetchReportTypes();
  const [chosenReportType, setChosenReportType] = useRecoilState(chosenReportTypeRecoil);
  const reportTypes = useRecoilValue(reportTypesRecoil);
  const reportTypesData = useRecoilValue(reportTypesFilteredRecoil);
  const resetChosenReportType = useResetRecoilState(chosenReportTypeRecoil);

  // choosing template mechanism
  const { isLoading: isTemplatesLoading, isError: isTemplatesError, refetch } = useFetchTemplates(true);
  const [reportTemplates, setReportTemplates] = useRecoilState(reportTemplatesRecoil);
  const [chosenReportTemplate, setChosenReportTemplate] = useRecoilState(chosenReportTemplateRecoil);
  const reportTemplatesFiltered = useRecoilValue(reportTemplatesFilteredRecoil);
  const templatesFiltered = useRecoilValue(templatesFilteredRecoil);
  const resetReportTemplates = useResetRecoilState(reportTemplatesRecoil);
  const resetChosenReportTemplates = useResetRecoilState(chosenReportTemplateRecoil);

  // chosing region mechanism
  const {
    isLoading: isRegionsLoading,
    isError: isRegionsError,
    mutate,
  } = useFetchReportRegions(
    chosenReportTemplate.length == 1 ? [chosenReportTemplate[0].id] : chosenReportTemplate.map((el) => el.id),
  );
  const [chosenReportRegion, setChosenReportRegion] = useRecoilState(chosenReportRegionRecoil);
  const reportRegionsFiltered = useRecoilValue(reportRegionsFilteredRecoil);
  const resetReportRegions = useResetRecoilState(reportRegionsRecoil);
  const resetChosenReportRegions = useResetRecoilState(chosenReportRegionRecoil);

  // choosing unit mechanism
  const {
    data: reportUnitsForRegion,
    isLoading: isReportUnitsForRegionLoading,
    isError: isReportUnitsForRegionError,
    mutate: fetchReportUnitsForRegionMutate,
  } = useFetchReportUnitsForRegion(chosenReportRegion.id, chosenReportTemplate[0]?.id);
  const [chosenReportUnit, setChosenReportUnit] = useState({});
  const filteredReportUnitsForRegion = reportUnitsForRegion ? filterBySearch(search, reportUnitsForRegion) : [];

  // chosing date mechanism
  const [ranges, setRanges] = useRecoilState(reportsDateRange);
  const [chosenReportDateRange, setChosenReportDateRange] = useRecoilState(chosenReportDateRangeRecoil);
  const resetChosenReportDateRange = useResetRecoilState(chosenReportDateRangeRecoil);
  const resetDateRanges = useResetRecoilState(reportsDateRange);
  const startDate = formatDate(ranges.startDate);
  const endDate = formatDate(ranges.endDate);

  // checkboxes mechanism
  const [recalculateLevel, setRecalculateLevel] = useState(false);
  const [onlyMostRecentAssessment, setOnlyMostRecentAssessment] = useState(true);
  const [usePseudonymizedData, setUsePseudonymizedData] = useState(true);
  const [isAuthorizedForPseudomizedData, setIsAuthorizedForPseudomizedData] = useState(false);

  // create report mechanism
  const data = {
    dateFrom: startDate,
    dateTo: endDate,
    reportDefinitionId: chosenReportType.id,
    assessmentTemplateIds: chosenReportTemplate.map((el) => el.id),
    regionId: chosenReportRegion ? chosenReportRegion.id : null,
    unitId: chosenReportUnit ? chosenReportUnit.id : null,
    onlyMostRecentAssessmentResult: onlyMostRecentAssessment,
    usePseudonymizedData: usePseudonymizedData,
    recalculateLevel: recalculateLevel,
  };
  const {
    isLoading: isCreateReportLoading,
    isError: isCreateReportError,
    mutate: createReportMutate,
  } = useCreateReport(data);
  const reportData = useRecoilValue(reportDataRecoil);
  const resetReportData = useResetRecoilState(reportDataRecoil);
  const {
    isLoading: isReportStatusLoading,
    isError: isReportStatusError,
    refetch: reportStatusRefetch,
  } = useFetchJobStatus(reportData?.id);
  const reportStatus = useRecoilValue(jobStatus);
  const resetReportStatus = useResetRecoilState(jobStatus);
  const [isReportLoading, setIsReportLoading] = useState(false);
  const [isReportError, setIsReportError] = useState(false);
  const [useDate, setUseDate] = useState(true);

  // check if report fails
  useEffect(() => {
    if (reportStatus?.status === 'Failed') {
      setIsReportLoading(false);
    }
  }, [reportStatus]);

  // check report status
  useEffect(() => {
    if (Object.keys(reportData).length != 0 && reportStatus?.status != 'Failed') {
      reportStatusRefetch();
    }
  }, [reportData]);

  // download file depending on report status
  useEffect(() => {
    if (Object.keys(reportStatus).length == 0) return;
    if (reportStatus.progress !== 100 && reportStatus?.status != 'Failed') {
      setTimeout(function () {
        reportStatusRefetch();
      }, 5000);
      return;
    }
    if (reportStatus.progress === 100) {
      downloadXLSFile(reportData?.id, setIsReportError);
      setIsReportLoading(false);
      resetReportData();
      resetReportStatus();
      return;
    }
  }, [reportStatus]);

  // checking if user can see checkbox with usePseudonymizedData and reset data
  useEffect(() => {
    if (isReportError) {
      setIsReportLoading(false);
    }
    resetReportStatus();
    resetReportData();
    resetChosenReportType();
    resetChosenReportTemplates();
    resetChosenReportRegions();
    setChosenReportUnit({});
    resetChosenReportDateRange();
    resetDateRanges();
    if (user.hasAccessToReports) setIsAuthorizedForPseudomizedData(true);
  }, []);

  // fetching report templates
  useEffect(() => {
    if (Object.keys(chosenReportType).length == 0) return;
    if (chosenReportType.assessmentTemplates.length == 0) {
      refetch();
      return;
    }
    if (chosenReportType.assessmentTemplates.length === 1) {
      setReportTemplates(chosenReportType.assessmentTemplates);
      setChosenReportTemplate([chosenReportType.assessmentTemplates[0]]);
      return;
    }
    if (chosenReportType.assessmentTemplates.length > 0) {
      return setReportTemplates(chosenReportType.assessmentTemplates);
    }
  }, [chosenReportType]);

  // fetching report regions
  useEffect(() => {
    if (chosenReportTemplate.length == 0) return;
    if (chosenReportTemplate.length > 0) {
      mutate();
      return;
    }
  }, [chosenReportTemplate]);

  // fetching report units
  useEffect(() => {
    if (chosenReportTemplate.length == 1 && Object.keys(chosenReportRegion).length > 0) {
      fetchReportUnitsForRegionMutate();
    }
  }, [chosenReportTemplate, chosenReportRegion]);

  // setting chosen region if only one template
  useEffect(() => {
    if (reportRegionsFiltered.length == 1) {
      setChosenReportRegion(reportRegionsFiltered[0]);
    }
  }, [reportRegionsFiltered]);

  const handleChoosenDate = () => {
    setChosenReportDateRange(`${startDate} - ${endDate}`);
  };

  useEffect(() => {
    setChosenReportDateRange(`${startDate} - ${endDate}`);
  }, [chosenReportDateRange]);

  const getTodaysDateRange = () => {
    const todaysDate = new Date();
    const formattedDate = formatDate(todaysDate, 'yyyy-MM-dd');
    const todaysDateRange = `${formattedDate} - ${formattedDate}`;
    return todaysDateRange;
  };

  const handleClick = (element, type) => {
    if (type == 'raport') {
      setChosenReportType(element);
    }
    if (type == 'template') {
      !Array.isArray(element) ? setChosenReportTemplate([element]) : setChosenReportTemplate(element);
    }
    if (type == 'region') {
      setChosenReportRegion(element);
    }
    if (type == 'unit') {
      setChosenReportUnit(element);
    }
  };

  const handleClear = () => {
    resetChosenReportType();
    resetChosenReportTemplates();
    resetChosenReportRegions();
    resetChosenReportDateRange();
    resetDateRanges();
    setChosenReportUnit({});
  };

  const handleDelete = (type) => {
    if (type == 'raport') {
      resetChosenReportType();
      resetChosenReportTemplates();
      resetReportTemplates();
      resetChosenReportRegions();
      resetReportRegions();
      setChosenReportUnit({});
    }
    if (type == 'template') {
      resetChosenReportTemplates();
      resetChosenReportRegions();
      resetReportRegions();
      setChosenReportUnit({});
    }
    if (type == 'region') {
      resetChosenReportRegions();
      setChosenReportUnit({});
    }
    if (type == 'unit') {
      setChosenReportUnit({});
    }
  };

  const handleSubmit = () => {
    if (!useDate) {
      data.dateFrom = null;
      data.dateTo = null;
    }
    setIsReportLoading(true);
    createReportMutate();
  };

  if (
    isReportTypesLoading ||
    reportTypes.length == 0 ||
    isTemplatesLoading ||
    isRegionsLoading ||
    isCreateReportLoading ||
    isReportStatusLoading ||
    isReportLoading ||
    isReportUnitsForRegionLoading
  ) {
    return (
      <UILoadingModal
        customMessage={
          isReportLoading
            ? `Rapporten genereras, framsteg:
          ${reportStatus?.progress != null ? `${Math.round(reportStatus?.progress)}%` : '0%'}`
            : t('UIModals.loadingModalMessage')
        }
      />
    );
  }

  if (
    isReportTypesError ||
    isTemplatesError ||
    isRegionsError ||
    isCreateReportError ||
    isReportStatusError ||
    isReportError ||
    isReportUnitsForRegionError ||
    reportStatus?.status == 'Failed'
  ) {
    return <UIErrorModal message={t('UIModals.errorModalMessage')} />;
  }

  return (
    <UIStaticComponentsWrapper isTopMargin={false}>
      <div className="h-full w-full flex justify-center">
        <div className="flex flex-col mt-9 md:w-full report-page ">
          <div className="flex flex-col text-left mt-9 mb-6 reportTitle">
            <h2 className="text-2xl title secondary">{t('ReportsPage.titleText')}</h2>
            <p className="text-lg subtitle-secondary">{t('ReportsPage.infoText')}</p>
          </div>
          <div
            className={'pt-16 pb-10 px-20 md:pl-8 md:pr-8 md:pt-10 md:pb-8 ' + 'rounded-15px report-table min-w-[60vw]'}
          >
            <div className="grid grid-cols-2 md:grid-cols-1 gap-y-10 gap-x-4 mb-20">
              <ReportsPageReportItem
                icon={<FiHome />}
                title={t('ReportsPage.raportTypeText')}
                modalTitle={t('ReportsPage.raportTypeText')}
                type="raport"
                data={reportTypesData}
                chosenData={chosenReportType}
                handleClick={handleClick}
                handleDelete={handleDelete}
                isExtraInfo={true}
                helpText={t('ReportsPage.RaportTypeHelpText')}
              />
              <ReportsPageReportItem
                icon={<MdOutlineSpaceDashboard />}
                title={t('ReportsPage.templateText')}
                modalTitle={t('ReportsPage.templateModalTitle')}
                type="template"
                data={reportTemplates.length > 0 ? reportTemplatesFiltered : templatesFiltered}
                chosenData={
                  chosenReportTemplate.length == 1
                    ? chosenReportTemplate[0].name
                    : chosenReportTemplate.map((el) => ' ' + el.name).toString()
                }
                handleClick={handleClick}
                handleDelete={handleDelete}
                isDisabled={Object.keys(chosenReportType).length == 0}
                isMultiple={chosenReportType.enableMultipleAssessmentTemplates}
              />
              <ReportsPageReportItem
                icon={<MdOutlinePlace />}
                title={t('ReportsPage.regionText')}
                type="region"
                data={reportRegionsFiltered}
                chosenData={chosenReportRegion}
                handleClick={handleClick}
                handleDelete={handleDelete}
                isDisabled={chosenReportTemplate.length == 0}
              />
              <ReportsPageReportItem
                icon={<MdOutlinePortrait />}
                title={t('ReportsPage.unitText')}
                type="unit"
                data={filteredReportUnitsForRegion}
                chosenData={chosenReportUnit}
                handleClick={handleClick}
                handleDelete={handleDelete}
                isDisabled={chosenReportTemplate.length != 1 || Object.keys(chosenReportRegion).length === 0}
              />
              {useDate && (
                <ReportsPageReportItem
                  icon={<MdOutlineCalendarToday />}
                  title={t('ReportsPage.dateText')}
                  type="date"
                  ranges={ranges}
                  setRanges={setRanges}
                  chosenData={chosenReportDateRange}
                  handleClick={handleChoosenDate}
                  isDateModal={true}
                  isExtraInfo={true}
                  helpText={t('ReportsPage.dateHelpText')}
                />
              )}
            </div>
            <div className="flex flex-col text-left items-start space-y-4">
              <UIButtonCheckbox
                description={t('ReportsPage.recalculateLevelText')}
                checked={recalculateLevel}
                setChecked={setRecalculateLevel}
                isDisabled={!chosenReportType?.includeCalculationLevel || !user.superAdministrator}
                styleDesc="text-18px font-normal"
              />
              <UIButtonCheckbox
                description={t('ReportsPage.latestAssessmentCheckboxLabel')}
                checked={onlyMostRecentAssessment}
                setChecked={setOnlyMostRecentAssessment}
                styleDesc="text-18px"
              />
              <UIButtonCheckbox
                description={t('ReportsPage.pseudonymizedDataCheckboxLabel')}
                checked={usePseudonymizedData}
                setChecked={setUsePseudonymizedData}
                isDisabled={!isAuthorizedForPseudomizedData}
                styleDesc="text-18px"
              />
              <UIButtonCheckbox
                description={t('ReportsPage.useDate')}
                checked={useDate}
                setChecked={setUseDate}
                styleDesc="text-18px"
              />
            </div>
          </div>
          <div className="flex justify-end my-10 pb-10">
            <button
              onClick={handleClear}
              className={
                'rounded-10px py-2 px-8 button-outlined ' +
                'text-sm xl:text-xs font-semibold rounded-md border ' +
                `${
                  Object.keys(chosenReportType).length !== 0 || chosenReportDateRange !== getTodaysDateRange()
                    ? ' opacity-100 cursor-pointer hov-red'
                    : ' opacity-50 cursor-not-allowed'
                }`
              }
            >
              {t('ReportsPage.clearSelectedText')}
            </button>
            <div className="w-36 h-11 flex justify-end ml-5">
              <UIButtonSubmit
                name={t('ReportsPage.retrieveSubmitButonText')}
                isIcon={false}
                assessments={true}
                onSubmit={() => handleSubmit()}
                enabled={
                  Object.keys(chosenReportType).length !== 0 &&
                  chosenReportTemplate.length != 0 &&
                  Object.keys(chosenReportRegion).length !== 0
                }
              />
            </div>
          </div>
        </div>
      </div>
    </UIStaticComponentsWrapper>
  );
};

export default ReportsPage;
