import mutation from '../useQueryWrapper';
import actions from 'api/report';

const useFetchReportUnitsForRegion = (chosenRegion, chosenTemplate) => {
  return mutation.useMutationWrapper(
    async () => {
      const reportUnitsForRegion = await actions.fetchReportUnitsForRegion(chosenRegion, chosenTemplate);
      return reportUnitsForRegion;
    },
    {
      refetchOnWindowFocus: false,
    },
  );
};

export default useFetchReportUnitsForRegion;
